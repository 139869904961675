/* Section 06 */
#bens-section-06 {
  background-color: var(--color-blue-light);
  border-radius: 8rem;
  flex: 100%;
  width: 100%;
  overflow: hidden;
  padding: 1rem 2rem;
  position: absolute;
  z-index: 2;
  top: -14rem;
  box-shadow: 0 1px 4px 0 var(--shadow-25);
}

.section-06-title {
  flex: 100%;
  color: var(--color-blue);
  text-align: center;
  margin: 0.5rem auto;
}
.trustpilot-widget.section-06 {
  padding: 1rem 1.5rem;
  border-radius: 1em 2em 1em 1em;
  margin: 1em auto;
  background-color: var(--color-white);
}
.trustpilot-widget.is-carousel {
  width: 100%;
  min-height: 200px;
  padding: 2rem 1rem;
  border-radius: 1rem;
  margin: auto 0;
}
.section-06-exprience {
  flex: 100%;
  margin: 1rem 0 2rem 0;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
}
.review-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: calc(100% / 5);
  padding: 1rem;
  margin: 0.5rem;
  height: 10rem;
  overflow: hidden;
  text-align: center;
  background-color: var(--color-white);
  border-radius: 2rem 2rem 2rem 0;
  transition: all 0.2s linear;
  box-shadow: 0 1px 1px 0 var(--shadow-25);
}
.review-box-name {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--color-dark);
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.review-box-name > img {
  margin-right: 1rem;
}
.review-box-msg {
  height: 4.5rem;
  overflow: hidden;
  padding: 0;
}

.section-06-button {
  text-decoration: none;
  background-color: var(--color-blue);
  color: var(--color-white);
  padding: 0.5rem 2rem;
  border-radius: 1rem 2rem 1rem 1rem;
  margin: 0.5rem auto;
  transition: all 0.2s linear;
}
.section-06-button:hover,
.section-06-button:active,
.section-06-button:focus {
  color: var(--color-white);
}

/* 2xl */
@media (max-width: 1536px) {
}

/* xl */
@media (max-width: 1280px) {
}

/* lg */
@media (max-width: 1024px) {
}

/* md */
@media (max-width: 768px) {
  #bens-section-06 {
    padding: 3rem 0;
  }
  .review-box {
    flex: calc(100% / 3);
  }
}

/* sm */
@media (max-width: 640px) {
  #bens-section-06 {
    padding: 3rem 0;
  }
  .review-box {
    flex: 100%;
  }
}
