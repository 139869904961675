@import "bootstrap/dist/css/bootstrap.min.css";

:root {
  --color-transparent: transparent;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-gray: #f9f9f9;
  --color-gray-dark: #857f7f;
  --color-gray-dark-1: #333;
  --color-blue: #3589a1;
  --color-blue-light: #eff9fd;
  --color-blue-light-1: #65e6a7;
  --color-blue-dark: #1d748d;
  --color-red: #b20000;
  --color-red-light: #ff4438;
  --color-green: #68c168;
  --color-green-dark: #00b200;
  --color-green-light: #f1fbee;
  --color-green-light-1: #b6e0a9;
  --color-purple: #0000a9;
  --color-purple-light: #f2f2ff;
  --shadow-25: rgba(0, 0, 0, 0.25);
  --shadow-50: rgba(0, 0, 0, 0.5);
  --shadow-primary-25: rgb(53, 137, 161, 0.25);
  --shadow-primary-50: rgb(53, 137, 161, 0.25);
}

a {
  color: var(--color-blue);
  transition: all 0.2s linear;
  text-align: center;
}

a:hover,
a:focus,
a:active {
  color: var(--color-blue-dark);
}

::selection {
  background-color: var(--color-blue-light);
  color: var(--color-blue-dark);
}

body {
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  background-color: var(--color-white);
  scroll-behavior: smooth;
}

.bens-container {
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

.bens-container-fluid {
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

.bens-wrap {
  display: flex;
  flex-wrap: wrap;
}

.bens-row {
  display: flex;
  flex-direction: row;
}

.bens-col {
  display: flex;
  flex-direction: column;
}

.bens-justify-content-center {
  justify-content: center;
}

.bens-aling-items-center {
  align-items: center;
}

.bens-justify-content-space-between {
  justify-content: space-between;
}

.page-title {
  color: var(--color-blue);
  text-align: center;
  margin: 0 auto 0.5rem auto;
  font-size: 2rem;
  line-height: 1.5;
}

.page-desc {
  color: var(--color-blue);
  text-align: center;
  margin: 0 auto 0.5rem auto;
  font-size: 1.25rem;
  line-height: 1.5;
}

.page-download-mobile-app,
.page-plus-info,
.page-tutorial {
  background-color: var(--color-blue-dark);
  display: flex;
  flex-direction: column;
}

.dma-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 16rem;
  margin: 30vh auto 1rem auto;
  background-color: var(--color-blue);
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 1px 4px 0 var(--shadow-50);
}

.dma-logo h1 {
  color: var(--color-white);
  margin: 1rem auto 2rem;
  font-size: 1.5rem;
  line-height: 1.5;
}

.dma-logo img {
  margin: 1rem auto;
}

.nos-solutions-de-change {
  display: flex;
  flex-direction: column;
  background-color: var(--color-blue-dark);
}

.nos-solutions-de-change img {
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

.nos-container {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  margin: 5vh auto;
  background-color: var(--color-blue);
  box-shadow: 0 1px 10px 0 var(--shadow-50);
  border-radius: 1rem;
}

.nos-section {
  display: flex;
  flex-direction: column;
  margin: 0 0 2rem 0;
}

.nos-section:first-child {
  margin-top: 2rem;
}

.nos-section:last-child {
  margin: 0;
}

.nos-section>.has-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  margin: 0;
  padding: 1rem;
  border-radius: 0 0 1rem 1rem;
}

.nos-section>.has-image>img {
  object-fit: contain;
  height: 3rem;
  width: auto;
}

.nos-section-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: var(--color-white);
  margin: 0 auto;
  border-radius: 0.5rem;
  box-shadow: 0 1px 4px 0 var(--shadow-50);
  padding: 1rem;
  max-width: 16rem;
}

.nos-section-logo>img {
  height: 5rem;
}

.nos-section-title {
  background-color: var(--color-red-light);
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2;
  text-align: center;
}

.nos-section-links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.nos-section-links>a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  max-width: 8rem;
  background-color: var(--color-blue-dark);
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 1rem;
  box-shadow: 0 1px 4px 0 var(--shadow-25);
  border: 0.2rem solid var(--color-white);
}

.nos-section-links>a>img {
  margin: auto;
  object-fit: contain;
  max-width: 100%;
  height: 3rem;
}

@media screen and (max-width: 600px) {
  .nos-container {
    max-width: 22em;
  }
}

/* 2xl */
@media (max-width: 1536px) {}

/* xl */
@media (max-width: 1280px) {}

/* lg */
@media (max-width: 1024px) {}

/* md */
@media (max-width: 768px) {}

/* sm */
@media (max-width: 640px) {
  h2 {
    font-size: calc(100vw / 17);
  }
}