/* Section 08 */
.solutions {
  padding: 4rem 0;
  position: relative;
  z-index: 3;
}

.solutions-back {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  right: auto;
  left: -65vw;
  margin: auto;
  width: 100vw;
  height: 100vw;
  border-radius: 100%;
  padding: 1rem;
}

.solutions > .bens-container {
  position: relative;
  z-index: 1;
}

.section-08-box {
  flex: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section-08-box > .box-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.section-08-box > .box-col > .box-img {
  object-fit: contain;
  max-width: 460px;
  margin: 1rem auto;
}

.section-08-box > .box-col > .box-title {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.6;
  text-align: left;
  margin: 0.5rem auto;
  background: linear-gradient(to right, var(--color-blue) 0%, var(--color-green) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: var(--color-white);
}

.section-08-box .box-desc-img {
  object-fit: contain;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.section-08-box .box-long-desc {
  color: var(--color-dark);
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 300;
}

.section-05-box .box-store {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.solutions-top {
  display: flex;
  flex-wrap: wrap;
}

.solutions-top > .box-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: calc(100% / 2);
}

.solutions-top-right {
  padding: 2rem;
}

.solutions-top-right > h2 {
  text-align: left;
  background: linear-gradient(to right, var(--color-blue) 0%, var(--color-green) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.6;
  margin: 1rem 0;
}

.solutions-top-right > a {
  text-decoration: none;
  background-color: var(--color-blue);
  border-radius: 1rem 2rem 1rem 1rem;
  color: var(--color-white);
  padding: 1rem 2rem;
  margin: 0.5rem auto 0.5rem 0;
  transition: all 0.2s linear;
  box-shadow: 0 1px 4px 0 var(--shadow-25);
}

.solutions-top-right > a:hover,
.solutions-top-right > a:focus,
.solutions-top-right > a:active {
  background-color: var(--color-blue-dark);
  box-shadow: 0 2px 8px 0 var(--shadow-50);
}

.media-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 24rem;
}

.media-item {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 var(--shadow-primary-25);
  transition: all 0.2s linear;
}

.media-item:hover,
.media-item:focus,
.media-item:active {
  text-decoration: none;
  box-shadow: 0 8px 32px 0 var(--shadow-primary-50);
  transform: scale(1.1);
}

.media-group > .media-item:last-child {
  align-self: flex-end;
}

.media-item > h4 {
  position: absolute;
  color: var(--color-white);
  text-align: center;
  margin: 1rem 2rem;
  font-size: 1.1rem;
  line-height: 1.6;
  z-index: 1;
  bottom: 2rem;
}

.media-item > img {
  height: 15rem;
  width: auto;
  object-fit: contain;
}

.solutions-title {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.5;
  background: linear-gradient(to right, var(--color-blue) 0%, var(--color-green) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin: 1rem auto;
  flex: 100%;
}

.solutions-desc {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5;
  padding: 0 1rem;
  margin: 0.5rem auto;
  text-align: center;
}

.solutions-circles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex: 100%;
  width: 100%;
  margin: 3rem auto 2rem auto;
}

.circle-item {
  display: flex;
  flex-wrap: wrap;
  flex: calc(100% / 4);
  max-width: calc(100% / 4);
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0 0 2px 0 var(--shadow-25);
  background-color: var(--color-white);
  position: relative;
  z-index: 1;
  transition: all 0.2s linear;
}

.circle-item:hover {
  box-shadow: 0 0 10px 0 var(--shadow-primary-50);
  transform: scale(1.25);
  z-index: 2;
}

.circle-item > img {
  width: 14rem;
  height: 14rem;
  object-fit: contain;
  margin: 0 auto 1rem auto;
  transition: transform 1s;
}

.circle-item:hover > img:not(.sponsor) {
  transform: rotateY(360deg);
}

.circle-item > img.sponsor {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: auto;
  height: 2rem;
  object-fit: contain;
  margin: 0;
  border-radius: 0.25rem;
  box-shadow: 0 0 2px 0 var(--shadow-25);
  background-color: var(--color-white);
  padding: 0.25rem;
  transition: all 0.2s linear;
}

.circle-item:hover > img.sponsor {
  box-shadow: 0 0 10px 0 var(--shadow-primary-50);
}

.circle-item > .info {
  position: absolute;
  z-index: 0;
  bottom: 1rem;
  font-size: 1.1rem;
  line-height: 1.5;
  text-align: center;
  padding: 0 1rem;
  margin: 0;
}

.circle-item > .info > h3 {
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

.circle-item > .info > h4 {
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.circle-item > span {
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: auto;
  border: auto;
  z-index: 0;
  color: var(--color-red);
  font-size: 1rem;
  font-weight: bold;
}

.solutions-text {
  display: flex;
  flex-wrap: wrap;
  margin-top: 6rem;
}

.sol-text-col {
  display: flex;
  flex-direction: column;
  flex: calc(100% / 4);
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 1rem;
  word-wrap: normal;
  word-break: break-all;
}

.sol-text-col > p {
  font-size: 1.25rem;
  line-height: 1.6;
}

.sol-text-col > p > b {
  background-color: var(--color-red);
  color: var(--color-white);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.5rem;
}

/* 2xl */
@media (max-width: 1536px) {
}

/* xl */
@media (max-width: 1280px) {
  .section-08-box > .box-col > .box-title {
    text-align: center;
  }
}

/* lg */
@media (max-width: 1024px) {
  .section-08-box > .box-col > .box-title {
    text-align: center;
  }

  .section-08-box {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .section-08-box > .box-col {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 1rem;
    width: 100%;
  }
}

/* md */
@media (max-width: 768px) {
  .solutions {
    padding: 2rem 0;
  }

  .section-08-box > .box-col > .box-title {
    text-align: center;
  }

  .section-08-box {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    padding-bottom: 12rem;
  }

  .section-08-box > .box-col {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 1rem;
    width: 100%;
  }

  .solutions-top-right > h2,
  .solutions-top-right > a {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .circle-item,
  .sol-text-col {
    flex: 100%;
    margin: 1rem auto;
    max-width: 20rem;
  }
}

/* sm */
@media (max-width: 640px) {
  .solutions {
    padding: 1rem 0;
  }

  .media-group {
    flex-direction: column;
    height: auto;
  }

  .media-group > .media-item {
    margin: 1rem auto;
  }

  .section-08-box > .box-col > .box-title {
    text-align: center;
  }

  .section-08-box {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .section-08-box > .box-col {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 1rem;
    width: 100%;
  }

  .section-08-box > .box-col > .box-img {
    max-width: 100%;
    width: 100%;
    height: auto;
    margin: 1rem auto;
  }

  .section-08-box .box-desc-img {
    max-width: 100%;
  }
}
